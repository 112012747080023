





































import { Component, Mixins, Watch } from 'vue-property-decorator';

import PageHeader from '@/components/PageHeader/PageHeader.vue';
import ListBooks from '@/components/ListBooks/ListBooks.vue';
import Footer from '@/components/Footer/Footer.vue';
import LastVideo from '@/widgets/Video/LastVideo/LastVideo.vue';

import CoursesService from '@/services/Courses/CoursesService';

import generalBooks from '@/mixins/generalBooks';
import AccessCourse from '@/mixins/AccessCourses';

import { IDS_FOR_PERMISSION_COURSE, ID_COURSE_VEST_MAPA } from '@/constant/CoursesId';
import { LIST_PERMISSION } from '@/constant/ListPermission';
import { LIST_NAME_CONTEXT } from '@/constant/Context';

import { Course, NewCourse } from '@/globalInterfaces/Course';

const LINK_COURSE_VESTMAPA = 'https://pages.explicae.com.br/redacao?utm_source=plataformafree&utm_medium=featurecursos&utm_term=veroutrosplanos#pricing';

@Component({
  components: {
    PageHeader,
    ListBooks,
    LastVideo,
    Footer,
  },
})
export default class Books extends Mixins(generalBooks, AccessCourse) {
  private isLoading = true;

  private listCourses: Array<NewCourse> = [];

  private context = LIST_NAME_CONTEXT;

  private coursesService = new CoursesService();

  async created() {
    await this.setCourses();
  }

  get isMobile() {
    return this.$responsive.isMobile;
  }

  get hasPermission() {
    return Object.values(IDS_FOR_PERMISSION_COURSE).some((permission: string) => this.can(permission));
  }

  get showAds() {
    return this.can(LIST_PERMISSION.ADS);
  }

  @Watch('hasPermission', { immediate: true })
  setPermissionStore() {
    this.$store.commit('setPermissionListCourses', this.hasPermission);
  }

  async setCourses() {
    try {
      this.isLoading = true;

      const courses = await this.coursesService.getCourses();

      let listCourses = courses.map((course: Course) => ({
        ...course,
        id: course.idCourses,
        link: '',
        redirect: this.verifyCourse,
        firstAccess: this.checkCourseAccess(course.idCourses),
        disable: false,
        // disable: this.isDisableCourse(Number(course.idCourses)),
        type: 'courses',
        linkRedirectFeedback: course.idCourses === ID_COURSE_VEST_MAPA ? LINK_COURSE_VESTMAPA : '',
      }));

      listCourses = this.verifyPermissionCourses(listCourses);

      this.listCourses = listCourses.sort((courseA: Record<string, any>, courseB: Record<string, any>) => courseA.disable - courseB.disable);
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar os cursos.',
        status: 'error',
      });

      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }

  verifyPermissionCourses(listCourse: Array<NewCourse>) {
    if (!this.showAds) {
      return listCourse.filter((course: NewCourse) => !course.disable);
    }

    return listCourse;
  }

  verifyCourse(idCourse: number) {
    const courseSelected = this.listCourses.find((course: NewCourse) => course.idCourses === idCourse);

    if (courseSelected) this.verifyFirstAccessCourse(courseSelected);
  }
}
